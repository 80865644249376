.drawer-box {
  .MuiDrawer-paper {
    background-color: #e2e8f0 !important;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(30 41 59);
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(30 41 59);
  }
}
.active-side {
  background-color: rgb(255 255 255 / 85%);
  border-radius: 0.75rem;
  color: rgb(22 78 99);
  font-size: 14px;
}
.sidebarlist {
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  margin-left: 10px !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #475569;
  width: calc(100% - 30px);
}
.sub-sidebarlist {
  font-family: "Inter", sans-serif !important;
  margin-left: 13px !important;
  font-weight: 500;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
}
.main-menus {
  min-height: 40px !important;
  margin: 4px 0px;
  transition: all 1s;
  &:hover {
    background-color: rgb(255 255 255 / 85%);
    border-radius: 0.75rem;
    color: rgb(22 78 99);
    font-size: 14px;
  }
}
.sub-menus {
  padding: 10px 0px !important;
  cursor: pointer;
}
.sub-menu-box {
  background-color: rgb(255 255 255 / 60%);
  border-radius: 0.75rem;
}
.side-baar-icon {
  width: 20px !important;
  height: 20px !important;
  color: #475569;
}
.active-side .side-baar-icon {
  color: rgb(22 78 99) !important;
}
.sidebar-label {
  font-size: 13px !important;
  color: #687693 !important;
  font-weight: 400;
  font-family: "Inter", sans-serif !important;
  margin: 10px 0px !important;
}
.sub-menus .side-baar-icon {
  color: #687693 !important;
}
.vishal-logo {
  width: 110px;
  margin: 0 auto;
}
.top-baar {
  background-color: #164e63 !important;
  color: #ffffff !important;
  width: calc(100% - 310px) !important;
  border-radius: 15px;
  margin: 15px;
  .header-toolbar {
    justify-content: space-between;
    .account-box {
      display: flex;
      align-items: center;
      .account-image {
        width: 40px;
        height: 40px;
      }
      .account-desc {
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;
        align-items: center;
        .account-heading {
          text-align: left;
          font-size: 14px;
          font-weight: 500;
          font-family: "Josefin Sans", sans-serif !important;
          color: #ffffff;
        }
        .account-sub {
          font-family: "Inter", sans-serif !important;
          font-size: 12px;
          font-weight: 400;
          color: #ffffffb3;
          text-align: left;
        }
      }
    }
  }
}
