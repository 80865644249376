
.App {
  text-align: center;
  font-family: "Josefin Sans", sans-serif !important;
}
.MuiOutlinedInput-input::placeholder {
  font-family: "Inter", sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Side baar */

.sidebarlist {
  font-family: "Inter", sans-serif !important;
  margin-left: 12px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sub-sidebarlist {
  font-family: "Inter", sans-serif !important;
  margin-left: 12px !important;
  font-weight: 600;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.side-baar-icon {
  width: 18px !important;
  height: 18px !important;
  color: #687693;
}
.active-side .side-baar-icon {
  color: black !important;
}
.top-baar {
  background-color: #164e63 !important;
  color: #ffffff !important;
  width: calc(100% - 310px) !important;
  border-radius: 15px;
  margin: 15px;
  z-index: 1 !important;
  .header-toolbar {
    justify-content: space-between;
    .h2 {
      font-size: 14px !important;
      color: #ffffffb3;
    }
    .bread-crumb-first {
      font-size: 14px !important;
      margin-right: 6px;
    }
    .account-box {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px;
      transition: all 0.7s;
      &:hover {
        background-color: #ffffff30;
        border-radius: 5px;
      }
      &:active {
        background-color: #ffffff30;
        border-radius: 5px;
      }
      &:focus-visible {
        background-color: #ffffff30;
        border-radius: 5px;
      }
      .account-image {
        width: 40px;
        height: 40px;
      }
      .account-desc {
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;
        align-items: center;
        .account-heading {
          text-align: left;
          font-size: 16px;
          font-weight: 500;
          font-family: "Josefin Sans", sans-serif !important;
          color: #000000;
        }
        .account-sub {
          font-family: "Inter", sans-serif !important;
          font-size: 13px;
          font-weight: 400;
          color: #687693;
          text-align: left;
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .App .MuiBox-root .top-baar {
    width: 96% !important;
    margin: 15px auto !important;
    right: 0;
    left: 0;
  }
  .header-toolbar .h2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .three-baar-icon {
    margin-right: 8px;
    margin-bottom: 2px;
  }
}
@media (max-width: 600px) {
  .top-baar .header-toolbar .account-box .account-desc {
    display: none !important;
  }
  .top-baar .header-toolbar .h2,
  .top-baar .header-toolbar .bread-crumb-first {
    font-size: 13px !important;
  }
  .top-baar .header-toolbar{
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}
input:focus{
  border: 0.8px solid #164e63;
}